<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap navbar-expand-lg p-0 shadow">
	<a class="navbar-brand col-sm-3 col-md-2 mr-0" href="/">{{title}}</a>
  
	<ng-container *ngIf="(isLoggedIn | async); else notLoggedIn">
	  <ul class="navbar-nav ml-auto navbar-right d-flex align-items-center">
		<li class="nav-item mr-3">
		  <div class="d-flex">
			<button class="btn btn-outline-secondary btn-sm mr-2" (click)="switchLanguage('en')">English</button>
			<button class="btn btn-outline-secondary btn-sm" (click)="switchLanguage('es')">Español</button>
		  </div>
		</li>
		<li class="nav-item ml-2">
		  <div class="btn-group">
			<button type="button" class="btn btn-dark dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
			  ({{(username|async)}})
			</button>
			<div class="dropdown-menu dropdown-menu-right">
			  <button class="dropdown-item" type="button" routerLinkActive="active" routerLink="/myaccount">
				<i class="bi bi-person-circle"></i> My Account
			  </button>
			  <button class="dropdown-item" type="button" (click)="logout(true)">
				<i class="bi bi-box-arrow-right"></i> Log out
			  </button>
			</div>
		  </div>
		</li>
	  </ul>
	</ng-container>
  
	<ng-template #notLoggedIn>
	  <ul class="navbar-nav ml-auto navbar-right d-flex align-items-center">
		<li class="nav-item mr-3">
		  <div class="d-flex">
			<button class="btn btn-outline-secondary btn-sm mr-2" (click)="switchLanguage('en')">English</button>
			<button class="btn btn-outline-secondary btn-sm" (click)="switchLanguage('es')">Español</button>
		  </div>
		</li>
		<li class="nav-item">
		  <button class="btn btn-outline-light my-2 my-sm-0" routerLink="/login">Login</button>
		</li>
	  </ul>
	</ng-template>
  </nav>