<ngb-toast
	*ngFor="let item of getMessages()"
	class="toast text-light fade show bg-{{ item.level }}"
	[autohide]="item.delay !== 0"
	[delay]="item.delay || 10000"
	(hidden)="messageService.close(item)"
>
	<button
		type="button"
		aria-label="Close"
		class="close clearfix"
		(click)="close(item)"
	>
		<span aria-hidden="true" style="color: whitesmoke">×</span>
	</button>

	<p>{{ item.message }}</p>
</ngb-toast>
