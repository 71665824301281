<nav class="col-md-2 d-none d-md-block bg-dark sidebar">
	<div class="sidebar-sticky">
		<div class="d-flex justify-content-start mt-1">
			<img src="/assets/pch_logo_site.png"/>
		</div>

		<ul class="nav flex-column"><!-- main sidebar-->
			<li class="nav-item">
				<a class="nav-link" routerLinkActive="active" routerLink="/home">
					<i class="bi bi-house"></i>Home
				</a>
			</li>

			<!--hub-->
			<li class="nav-item" *ngIf="!isParticipantRole && (isLoggedIn | async)">
				<a class="nav-link" routerLinkActive="active" routerLink="/hub">
					<i class="bi bi-bullseye"></i>Hub
				</a>
			</li>

			<!--participants-->
			<li class="nav-item" *ngIf="isLoggedIn | async"></li>
			<li class="nav-item has-submenu" *ngIf="isLoggedIn | async">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#participants-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-search"></i>Participants
				</a>
				<ul class="submenu collapse" id="participants-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/participants"
						   [routerLinkActiveOptions]="{exact: true}">
							<i class="bi bi-bank"></i>Participants List
						</a>
					</li>
					<li *ngIf="!isParticipantRole">
						<a class="nav-link" routerLinkActive="active" routerLink="/participants/pending-approvals"
						   [routerLinkActiveOptions]="{exact: true}">
							<i class="bi bi-bank"></i>Pending Approval Requests
						</a>
					</li>
				</ul>
			</li>

			<!--lookup-->
			<li class="nav-item has-submenu" *ngIf="(isLoggedIn | async)">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#account-lookup-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-search"></i>Account Lookup
				</a>
				<ul class="submenu collapse" id="account-lookup-menu" routerLinkActive="show">
					<li *ngIf="!isParticipantRole">
						<a class="nav-link" routerLinkActive="active" routerLink="/account-lookup/oracles">
							Oracles
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/account-lookup/associations">
							Associations
						</a>
					</li>
				</ul>
			</li>

			<!--quotes-->
			<!-- <li class="nav-item has-submenu" *ngIf="(isLoggedIn | async)">
			  <a class="nav-link dropdown-toggle" routerLinkActive="active" href="#quotes-menu"
				data-toggle="collapse" role="button">
				<i class="bi bi-receipt"></i>Quotes
			  </a>
			  <ul class="submenu collapse" id="quotes-menu" routerLinkActive="show">
				<li>
				  <a class="nav-link" routerLinkActive="active" routerLink="/quotes">
					Quotes
				  </a>
				</li>
				<li>
				  <a class="nav-link" routerLinkActive="active" routerLink="/bulk-quotes">
					Bulk Quotes
				  </a>
				</li>
			  </ul>
			</li>
	   -->
			<!--transfers-->
			<li class="nav-item has-submenu" *ngIf="(isLoggedIn | async)">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#transfers-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-receipt"></i>Transfers
				</a>
				<ul class="submenu collapse" id="transfers-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/transfers">
							Transfers
						</a>
					</li>
					<!-- <li>
					  <a class="nav-link" routerLinkActive="active" routerLink="/bulk-transfers">
						Bulk Transfers
					  </a>
					</li> -->
				</ul>
			</li>

			<!--settlements-->
			<li class="nav-item has-submenu" *ngIf="!isParticipantRole && (isLoggedIn | async)">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#settlements-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-arrow-left-right"></i>Settlements
				</a>
				<ul class="submenu collapse" id="settlements-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/settlements/models">
							Models
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/settlements/batches">
							Batches
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/settlements/matrix">
							Matrices
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/settlements/transfers">
							Transfers
						</a>
					</li>
				</ul>
			</li>

			<!--report - disabled for now -->
			<!--		<li class="nav-item has-submenu" *ngIf="!isParticipantRole && (isLoggedIn | async)">
					  <a class="nav-link dropdown-toggle" routerLinkActive="active" href="#report-menu"
						data-toggle="collapse" role="button">
						<i class="bi bi-clipboard-data"></i>Reports
					  </a>
					  <ul class="submenu collapse" id="report-menu">
						<li>
						  <a class="nav-link" routerLinkActive="active" routerLink="/report/settlement-initiation-report">
							Settlement Initiation Report
						  </a>
						</li>
						<li>
						  <a class="nav-link" routerLinkActive="active" routerLink="/report/dfsp-settlement-report">
							DFSP Settlement Report
						  </a>
						</li>
						<li>
						  <a class="nav-link" routerLinkActive="active" routerLink="/report/dfsp-settlement-detail-report">
							DFSP Settlement Detail Report
						  </a>
						</li>
						<li>
						  <a class="nav-link" routerLinkActive="active" routerLink="/report/dfsp-settlement-statement-report">
							DFSP Settlement Statement Report
						  </a>
						</li>
					  </ul>
					</li>-->

			<!-- third party payment initiation -->
			<li class="nav-item has-submenu" *ngIf="(isLoggedIn | async)">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#transactions-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-receipt"></i>Thirdparty Payment Initiation
				</a>
				<ul class="submenu collapse" id="transactions-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/threeppi/onboard-requests">
							Onboard Requests
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/threeppi/consent-requests">
							Consent Requests
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/threeppi/transactions">
							Transactions
						</a>
					</li>
				</ul>
			</li>

			<!-- pisp -->
			<li class="nav-item has-submenu" *ngIf="(isLoggedIn | async)">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#pisp-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-receipt"></i>PISP
				</a>
				<ul class="submenu collapse" id="pisp-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/users-onboard">
							Users onboard
						</a>
					</li>
				</ul>
			</li>


			<!--notifications-->
			<li class="nav-item has-submenu" *ngIf="(isLoggedIn | async)">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#notifications-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-receipt"></i>Notifications
				</a>
				<ul class="submenu collapse" id="notifications-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications">
							Notifications
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications/new">
							Add new notification
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications/subscription-groups">
							Subscription Groups
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications/subscription-group/new">
							Add new subscription group
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications/subscriptions">
							Subscriptions
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications/subscription/new">
							Add new subscription
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications/templates">
							Templates
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications/template/new">
							Add new template
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/notifications/subscription-attempts">
							Subscription notification attempts
						</a>
					</li>
				</ul>
			</li>

			<!--schedules-->
			<li class="nav-item has-submenu" *ngIf="(isLoggedIn | async)">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#schedules-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-receipt"></i>Schedules
				</a>
				<ul class="submenu collapse" id="schedules-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/schedules">
							Schedules
						</a>
						<a class="nav-link" routerLinkActive="active" routerLink="/schedules/new">
							Add new schedule
						</a>
					</li>
				</ul>
			</li>
		</ul>
		<!-- main sidebar-->

		<h6 *ngIf="(isLoggedIn | async)"
			class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
			<span>Settings</span>
		</h6>

		<ul class="nav flex-column" *ngIf="(isLoggedIn | async)"><!-- secondary sidebar-->
			<!-- platform configuration -->
			<li class="nav-item has-submenu" *ngIf="!isParticipantRole">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#platform-config-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-sliders"></i>Platform Configuration
				</a>
				<ul class="submenu collapse" id="platform-config-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/platform-configuration/main">
							Main
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/platform-configuration/global">
							Global Configurations
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/platform-configuration/bcs">
							Bounded Context Configurations
						</a>
					</li>
				</ul>
			</li>

			<!-- security -->
			<li class="nav-item has-submenu" *ngIf="!isParticipantRole">
				<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#security-menu"
				   data-toggle="collapse" role="button">
					<i class="bi bi-shield-check"></i>Security
				</a>
				<ul class="submenu collapse" id="security-menu" routerLinkActive="show">
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/security/builtin_iam/users">
							Builtin IAM - Users
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/security/builtin_iam/apps">
							Builtin IAM - Applications
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/security/roles">
							Role Management
						</a>
					</li>
					<li>
						<a class="nav-link" routerLinkActive="active" routerLink="/security/privileges">
							Application Privileges
						</a>
					</li>
				</ul>
			</li>

			<!-- auditing -->
			<li class="nav-item" *ngIf="!isParticipantRole">
				<a class="nav-link" routerLinkActive="active" routerLink="/auditing">
					<i class="bi bi-eye"></i> Audit Entries
				</a>
			</li>

			<!-- tests -->
			<li class="nav-item">
				<a class="nav-link" routerLinkActive="active" routerLink="/tests">
					<i class="bi bi-bug"></i> Tests <span class="text-warning">(dev mode)</span>
				</a>
			</li>
		</ul><!-- secondary sidebar-->

		<div class="fixed-bottom d-flex align-items-center" style="margin-left: 70px; color: lightgrey;">
			<span>v {{ getVersion() }} - {{ getEnvName() }}</span>
		</div>
	</div>
</nav>
