<nav aria-label="Page navigation" *ngIf="paginateResult">
	<ul class="pagination justify-content-end">
		<li class="pagesize d-flex align-items-center">
			<span>Items per page:</span>
			<select
				(change)="onPageSizeChange($event)"
				class="rows-selector form-control ml-2"
				id="pageSize"
			>
				<option
					*ngFor="let size of pageSizeOptions"
					[value]="size"
					[selected]="paginateResult.pageSize == size"
				>
					{{ size }}
				</option>
			</select>
		</li>

		<li class="gotopage d-flex align-items-center">
			<span>Go to page</span>
			<select
				(change)="onPageChange($event)"
				class="rows-selector form-control ml-2"
				id="pageIndex"
			>
				<option
					*ngFor="
						let item of [].constructor(paginateResult.totalPages);
						let i = index
					"
					[value]="i"
					[selected]="paginateResult.current == i + 1"
				>
					{{ i + 1 }}
				</option>
			</select>
		</li>

		<li class="d-flex align-items-center">
			<span
				>Page {{ paginateResult.current }} of
				{{ paginateResult.totalPages }}</span
			>
		</li>

		<!-- first-->
		<li [ngClass]="{ disabled: paginateResult.prev == null }">
			<a class="page-link" aria-label="First" (click)="goToPage(0)">
				<span aria-hidden="true">&lt;&lt;</span>
				<span class="sr-only">First</span>
			</a>
		</li>

		<!-- previous-->
		<li [ngClass]="{ disabled: paginateResult.prev == null }">
			<a
				class="page-link"
				aria-label="Previous"
				(click)="
					goToPage(paginateResult.prev ? paginateResult.prev - 1 : 0)
				"
			>
				<span aria-hidden="true">&lt;</span>
				<span class="sr-only">Previous</span>
			</a>
		</li>

		<!-- next-->
		<li [ngClass]="{ disabled: paginateResult.next == null }">
			<a
				class="page-link"
				aria-label="Next"
				(click)="
					goToPage(paginateResult.next ? paginateResult.next - 1 : 0)
				"
			>
				<span aria-hidden="true">&gt;</span>
				<span class="sr-only">Next</span>
			</a>
		</li>

		<!-- last-->
		<li [ngClass]="{ disabled: paginateResult.next == null }">
			<a
				class="page-link"
				aria-label="Last"
				(click)="goToPage(paginateResult.totalPages - 1)"
			>
				<span aria-hidden="true">&gt;&gt;</span>
				<span class="sr-only">Last</span>
			</a>
		</li>
	</ul>
</nav>
