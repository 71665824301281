import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/_services_and_types/authentication.service";
import { SettingsService } from "src/app/_services_and_types/settings.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, OnDestroy {
	@Input() title: string = "";

	isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isParticipantRole = false;

	private isLoggedInSubs?: Subscription;

	constructor(
		private _authentication: AuthenticationService,
		private _settings: SettingsService,
		private translate: TranslateService,
	) {}

	ngOnInit(): void {
		this.isLoggedInSubs = this._authentication.LoggedInObs.subscribe(
			(value) => {
				this.isLoggedIn.next(value);
				this.isParticipantRole =
					this._authentication.platformRoles.length === 0 ||
					this._authentication.participantRoles.length > 0;
			},
		);
	}

	ngOnDestroy(): void {
		if (this.isLoggedInSubs) {
			this.isLoggedInSubs.unsubscribe();
		}
	}

	getVersion(): string {
		return this._settings.getVersion();
	}

	getEnvName(): string {
		return this._settings.envName;
	}

	switchLanguage(language: string): void {
		this.translate.use(language);
	}
}
