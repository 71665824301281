import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { MomentDatePipe } from "../_pipes_and_guards/moment-date";
import { TimezoneOffsetPipe } from "../_pipes_and_guards/timezone-offset";
import { DecimalValidatorDirective } from "../_directives/decimal-validator.directive";
import { DropZoneDirective } from "../_pipes_and_guards/drop-zone.directive";
import { TranslateModule } from "@ngx-translate/core";

import { PaginationComponent } from "./components/pagination/pagination.component";
import { ModalComponent } from "./components/modal/modal.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { MessagesComponent } from "./components/messages/messages.component";
import { LayoutComponent } from "./components/layout/layout.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		RouterModule,
	],
	declarations: [
		// Existing pipes and directives
		MomentDatePipe,
		TimezoneOffsetPipe,
		DecimalValidatorDirective,
		DropZoneDirective,

		// Components
		MessagesComponent,
		LayoutComponent,
		NavbarComponent,
		SidebarComponent,
		PaginationComponent,
		ModalComponent,
		FileUploadComponent,
	],
	exports: [
		// Modules
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		TranslateModule,

		// Pipes and Directives
		MomentDatePipe,
		TimezoneOffsetPipe,
		DecimalValidatorDirective,
		DropZoneDirective,

		// Components
		MessagesComponent,
		LayoutComponent,
		NavbarComponent,
		SidebarComponent,
		PaginationComponent,
		ModalComponent,
		FileUploadComponent,
	],
})
export class SharedModule {}
