import { Component, OnInit } from "@angular/core";
import { EventBusService } from "src/app/_services_and_types/eventbus.service";
import { MessageService } from "src/app/_services_and_types/message.service";

@Component({
	selector: "app-layout",
	templateUrl: "./layout.component.html",
	styleUrls: ["./layout.component.css"],
})
export class LayoutComponent implements OnInit {
	title = "PCH's Switch Admin UI";

	constructor(
		private _eventBusService: EventBusService,
		private _messages: MessageService,
	) {}

	ngOnInit(): void {
		this._eventBusService.on("LogoutForced", () => {
			this._messages.addError(
				"You have been logged out, please login again",
			);
		});
	}
}
