import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-file-upload",
	templateUrl: "./file-upload.component.html",
})
export class FileUploadComponent {
	@Input() accept: string = "*/*";
	@Input() uploadInProgress: boolean = false;
	@Input() uploadProgress: number = 0;
	@Input() uploadComplete: boolean = false;
	@Input() selectedFile: File | null = null;
	@Input() fileInputId: string = "fileUpload";
	@Input() uploadPrompt: string = "Drag & Drop your file here";

	@Output() fileSelected = new EventEmitter<File>();
	@Output() browseClick = new EventEmitter<void>();

	transitionClass = "circular-transition";
	dropZoneClass =
		"my-4 border p-4 rounded-lg d-flex flex-column gap-3 justify-content-center align-items-center";
	currentDropZoneClass = this.dropZoneClass;

	get strokeDashoffset(): number {
		return 190 - (190 * this.uploadProgress) / 100;
	}

	onDragOver(event: DragEvent) {
		event.preventDefault();
		this.currentDropZoneClass = `${this.dropZoneClass} dragging-over`;
	}

	onDragLeave(event: DragEvent) {
		event.preventDefault();
		this.currentDropZoneClass = this.dropZoneClass;
	}

	onDrop(event: DragEvent) {
		event.preventDefault();
		this.currentDropZoneClass = this.dropZoneClass;

		if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
			this.fileSelected.emit(event.dataTransfer.files[0]);
		}
	}

	onFileInputChange(event: Event) {
		const input = event.target as HTMLInputElement;
		if (input.files?.length) {
			this.fileSelected.emit(input.files[0]);
		}
	}

	openFileInput() {
		this.browseClick.emit();
	}
}
