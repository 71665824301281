import { NgModule } from "@angular/core";

import { RouterModule, Routes } from "@angular/router";
import { CanLoadIsLoggedIn } from "src/app/_pipes_and_guards/canload_isloggedin_guard";
import { FeatureGuard } from "./_pipes_and_guards/feature.guard";
import {ParticipantDetailComponent} from "./pages/participants/participant-detail.component";

const routes: Routes = [
	{ path: "", redirectTo: "/home", pathMatch: "full" },
	{
		path: "login",
		loadChildren: () =>
			import("./pages/login/login.module").then((m) => m.LoginModule),
	},
	{
		path: "home",
		loadChildren: () =>
			import("./pages/home/home.module").then((m) => m.HomeModule),
	},

	// IMPORTANT!!!
	// All routes after needs must include:
	// canActivate: [CanLoadIsLoggedIn]
	// to ensure valid login

	{
		path: "auditing",
		loadChildren: () =>
			import("./pages/auditing/auditing.module").then(
				(m) => m.AuditingModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "myaccount",
		loadChildren: () =>
			import("./pages/my-account/my-account.module").then(
				(m) => m.MyAccountModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "account-lookup",
		loadChildren: () =>
			import("./pages/account-lookup/account-lookup.module").then(
				(m) => m.AccountLookupModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "quotes",
		loadChildren: () =>
			import("./pages/quotes/quotes.module").then((m) => m.QuotesModule),
		canActivate: [CanLoadIsLoggedIn, FeatureGuard],
	},
	{
		path: "bulk-quotes",
		loadChildren: () =>
			import("./pages/bulk-quotes/bulk-quotes.module").then(
				(m) => m.BulkQuotesModule,
			),
		canActivate: [CanLoadIsLoggedIn, FeatureGuard],
	},
	{
		path: "transfers",
		loadChildren: () =>
			import("./pages/transfers/transfers.module").then(
				(m) => m.TransfersModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "bulk-transfers",
		loadChildren: () =>
			import("./pages/bulk-transfers/bulk-transfers.module").then(
				(m) => m.BulkTransfersModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		// use top level url for hub
		path: "hub",
		loadChildren: () =>
			import("./pages/participants/participants.module").then(
				(m) => m.ParticipantsModule,
			),
		canActivate: [CanLoadIsLoggedIn],
		component: ParticipantDetailComponent
	},
	{ path: "participants/hub", redirectTo: "hub", pathMatch: "full" }, // redirect to top level url
	{
		path: "participants",
		loadChildren: () =>
			import("./pages/participants/participants.module").then(
				(m) => m.ParticipantsModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "platform-configuration",
		loadChildren: () =>
			import(
				"./pages/platform-configuration/platform-configuration.module"
			).then((m) => m.PlatformConfigurationModule),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "settlements",
		loadChildren: () =>
			import("./pages/settlements/settlements.module").then(
				(m) => m.SettlementsModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	/*{
		path: "report",
		loadChildren: () =>
			import("./pages/reports/reports.module").then(
				(m) => m.ReportsModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},*/
	{
		path: "security",
		loadChildren: () =>
			import("./pages/security/security.module").then(
				(m) => m.SecurityModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "schedules",
		loadChildren: () =>
			import("./pages/scheduling/scheduling.module").then(
				(m) => m.SchedulingModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "threeppi",
		loadChildren: () =>
			import("./pages/threeppi/threeppi.module").then(
				(m) => m.ThreeppiModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "notifications",
		loadChildren: () =>
			import("./pages/notifications/notifications.module").then(
				(m) => m.NotificationsModule,
			),
		canActivate: [CanLoadIsLoggedIn],
	},
	{
		path: "tests",
		loadChildren: () =>
			import("./pages/tests/tests.module").then((m) => m.TestsModule),
		canActivate: [CanLoadIsLoggedIn],
	},
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
