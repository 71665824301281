import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/_services_and_types/authentication.service";
import { Router } from "@angular/router";
import { IBuiltinIamUser } from "src/app/_services_and_types/security_types";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit, OnDestroy {
	@Input() title: string = "";

	isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	username: BehaviorSubject<string> = new BehaviorSubject<string>("");

	private isLoggedInSubs?: Subscription;
	private usernameSubs?: Subscription;

	constructor(
		private _authentication: AuthenticationService,
		private _router: Router,
		private translate: TranslateService,
	) {}

	ngOnInit(): void {
		this.isLoggedInSubs = this._authentication.LoggedInObs.subscribe(
			(value) => {
				this.isLoggedIn.next(value);
			},
		);

		this.usernameSubs = this._authentication.UsernameObs.subscribe(
			(value) => {
				this.username.next(value);
			},
		);

		// Set English as default language
		this.translate.setDefaultLang("en");
		this.translate.use("en");
	}

	ngOnDestroy(): void {
		if (this.isLoggedInSubs) {
			this.isLoggedInSubs.unsubscribe();
		}

		if (this.usernameSubs) {
			this.usernameSubs.unsubscribe();
		}
	}

	logout(force: boolean = false): void {
		if (!this._authentication.accessToken && !force) return;
		this._authentication.logout();
		this._router.navigate(["/login"]);
	}

	switchLanguage(language: string): void {
		this.translate.use(language);
	}
}
