import {
	Component,
	Input,
	Output,
	EventEmitter,
	TemplateRef,
	ViewChild,
	OnChanges,
	SimpleChanges,
	OnDestroy,
	AfterViewInit,
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "app-modal",
	templateUrl: "./modal.component.html",
	styleUrl: "./modal.component.css",
})
export class ModalComponent implements OnChanges, OnDestroy, AfterViewInit {
	@Input() id: string = "";
	@Input() title: string = "";
	@Input() primaryButtonText: string = "Save";
	@Input() secondaryButtonText: string = "Cancel";
	@Input() primaryButtonClass: string = "btn-primary";
	@Input() secondaryButtonClass: string = "btn-outline-primary";
	@Input() contentTemplate!: TemplateRef<any>;
	@Input() isDisabled: boolean = false;
	@Input() visible: boolean = false;

	@Output() primaryAction = new EventEmitter<void>();
	@Output() secondaryAction = new EventEmitter<void>();
	@Output() visibleChange = new EventEmitter<boolean>();

	@ViewChild("modalTemplate") modalTemplate!: TemplateRef<any>;

	private modalRef: NgbModalRef | null = null;
	private viewInitialized = false;

	constructor(private modalService: NgbModal) {}

	ngAfterViewInit(): void {
		this.viewInitialized = true;

		if (this.visible) {
			setTimeout(() => this.open(), 0);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["visible"] && this.viewInitialized) {
			if (this.visible && !this.modalRef) {
				this.open();
			} else if (!this.visible && this.modalRef) {
				this.close(false);
			}
		}
	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.close();
			this.modalRef = null;
		}
	}

	open(): void {
		if (this.modalRef || !this.modalTemplate) return;

		this.modalRef = this.modalService.open(this.modalTemplate, {
			backdrop: true,
			keyboard: true,
			centered: true,
			size: "lg",
			ariaLabelledBy: `${this.id}-title`,
			windowClass: "clickable-modal",
		});

		this.modalRef.result.then(
			() => this.handleModalClosed(),
			() => this.handleModalClosed(),
		);
	}

	handleModalClosed(): void {
		this.modalRef = null;
		if (this.visible) {
			this.visible = false;
			this.visibleChange.emit(false);
		}
	}

	close(emitEvent: boolean = true): void {
		if (this.modalRef) {
			this.modalRef.close();
			this.modalRef = null;
		}

		if (emitEvent && this.visible) {
			this.visible = false;
			this.visibleChange.emit(false);
		}
	}

	onPrimaryActionClick(): void {
		this.primaryAction.emit();
	}

	onSecondaryActionClick(): void {
		this.secondaryAction.emit();
		this.close();
	}
}
