<app-navbar [title]="title"></app-navbar>

<div class="container-fluid side-bar-and-main">
  <div class="row">
    <app-sidebar></app-sidebar>
    
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <app-messages class="ngb-toasts" aria-atomic="true" aria-live="polite"></app-messages>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
