<ng-template #modalTemplate>
	<div class="modal-header bg-primary text-white">
		<h5 class="modal-title font-weight-bolder" [id]="id + '-title'">
			{{ title }}
		</h5>
		<button
			type="button"
			class="close text-white"
			(click)="onSecondaryActionClick()"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body pb-0">
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</div>

	<div class="modal-footer border-0 pt-0">
		<button
			type="button"
			class="btn {{ primaryButtonClass }}"
			[disabled]="isDisabled"
			(click)="onPrimaryActionClick()"
		>
			{{ primaryButtonText }}
		</button>
		<button
			type="button"
			class="btn {{ secondaryButtonClass }}"
			(click)="onSecondaryActionClick()"
		>
			{{ secondaryButtonText }}
		</button>
	</div>
</ng-template>

<div *ngIf="visible" style="display: none">
	<div class="modal fade show d-block" [id]="id" tabindex="-1" role="dialog">
		<div
			class="modal-dialog modal-lg modal-dialog-centered"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header bg-primary">
					<h5 class="modal-title">{{ title }}</h5>
				</div>
				<div class="modal-body"></div>
				<div class="modal-footer"></div>
			</div>
		</div>
	</div>
</div>
