<div
	(dragover)="onDragOver($event)"
	(dragleave)="onDragLeave($event)"
	(drop)="onDrop($event)"
	[ngClass]="currentDropZoneClass"
	style="border-style: dashed !important; text-align: center"
>
	<div
		style="
			position: relative;
			width: 5rem;
			height: 5rem;
			margin-bottom: 0.5rem;
		"
	>
		<i
			*ngIf="!uploadInProgress && !selectedFile"
			class="bi bi-cloud-arrow-up absolute-center"
			style="font-size: 3rem"
		>
		</i>

		<i
			*ngIf="uploadInProgress"
			class="bi bi-file-earmark-text absolute-center"
			style="font-size: 2rem"
		>
		</i>

		<i
			*ngIf="uploadComplete && selectedFile"
			class="bi bi-file-earmark-check text-success absolute-center"
			style="font-size: 2rem"
		>
		</i>

		<div *ngIf="uploadInProgress || selectedFile">
			<svg viewBox="0 0 80 80" style="transform: rotate(-90deg)">
				<circle
					cx="40"
					cy="40"
					r="30"
					stroke="#DDE1E3"
					fill="none"
					style="
						stroke-width: 4px;
						stroke-dashoffset: 0;
						stroke-dasharray: 190;
						stroke-linecap: round;
					"
				></circle>
				<circle
					cx="40"
					cy="40"
					r="30"
					stroke="#45a557"
					fill="none"
					[attr.stroke-dashoffset]="strokeDashoffset"
					style="
						stroke-width: 4px;
						stroke-dasharray: 190;
						stroke-linecap: round;
					"
					[ngClass]="transitionClass"
				></circle>
			</svg>
		</div>
	</div>

	<ng-container *ngIf="!uploadInProgress && !selectedFile">
		<p>{{ uploadPrompt }}</p>
		<p>OR</p>
		<button class="btn btn-primary mb-2" (click)="openFileInput()">
			Browse file
		</button>
		<input
			[id]="fileInputId"
			type="file"
			class="sr-only"
			[accept]="accept"
			(change)="onFileInputChange($event)"
		/>
	</ng-container>

	<ng-container *ngIf="uploadInProgress">
		<p>Uploading file...</p>
	</ng-container>

	<ng-container *ngIf="uploadComplete && selectedFile">
		<p>Upload complete!</p>
	</ng-container>
</div>
