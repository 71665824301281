import { Component, Input, Output, EventEmitter } from "@angular/core";
import { PaginateResult } from "../../../_utils";

@Component({
	selector: "app-pagination",
	templateUrl: "./pagination.component.html",
})
export class PaginationComponent {
	@Input() paginateResult: PaginateResult | null = null;
	@Input() pageSizeOptions: number[] = [5, 10, 25, 50, 100];

	@Output() pageChange = new EventEmitter<{
		pageIndex: number;
		pageSize: number;
	}>();

	goToPage(pageIndex: number): void {
		if (!this.paginateResult) return;

		this.pageChange.emit({
			pageIndex: pageIndex,
			pageSize: this.paginateResult.pageSize || 10,
		});
	}

	onPageSizeChange(event: Event): void {
		const target = event.target as HTMLSelectElement;
		const pageSize = parseInt(target.value, 10);

		this.pageChange.emit({
			pageIndex: 0,
			pageSize: pageSize,
		});
	}

	onPageChange(event: Event): void {
		const target = event.target as HTMLSelectElement;
		const pageIndex = parseInt(target.value, 10);

		this.pageChange.emit({
			pageIndex: pageIndex,
			pageSize: this.paginateResult?.pageSize || 10,
		});
	}
}
