import { Injectable } from "@angular/core";
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
} from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class FeatureGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean {
		const featureName = route.routeConfig
			?.path as keyof typeof environment.features;

		if (featureName && !environment.features[featureName]) {
			this.router.navigate(["/home"]);
			return false;
		}

		return true;
	}
}
