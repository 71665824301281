import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
	BrowserAnimationsModule,
	NoopAnimationsModule,
} from "@angular/platform-browser/animations";

import { MessagesComponent } from "./shared/components/messages/messages.component";

import { CanLoadIsLoggedIn } from "./_pipes_and_guards/canload_isloggedin_guard";
import { AuthInterceptor } from "./_pipes_and_guards/auth_interceptor";
import { InteropInterceptor } from "./_pipes_and_guards/interop_interceptor";
import { TestsComponent } from "./pages/tests/tests.component";
import { CanLoadWithUserType } from "./_pipes_and_guards/canload_withusertype_guard";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { SharedModule } from "./shared/shared.module";

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		NgbModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		NoopAnimationsModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) =>
					new TranslateHttpLoader(http, "./assets/i18n/", ".json"),
				deps: [HttpClient],
			},
		}),
	],
	exports: [],
	providers: [
		CanLoadIsLoggedIn,
		CanLoadWithUserType,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InteropInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
